import Axios from "axios";
import { GET_SUB_CONTEST, PUSH_QUESTION } from "../actionTypes";

export const getAllContest = () => async (dispatch) => {
  try {
    const name1 = await localStorage.getItem("contestName");
    console.log(name1);
    // const name1 = "        Carry    Minati";
    const respMasterId = await Axios.get(
      `https://api.tamasha.live/api/contest_master`
    );

    const MasterId = await respMasterId.data.filter(
      (contest) =>
        contest.ContestName.replace(/\s/g, "").toLowerCase() ===
        name1.replace(/\s/g, "").toLowerCase()
    );
    console.log(MasterId);
    const upcomingData = await Axios.get(
      `https://api.tamasha.live/api/other/upcoming_sub_contest/${MasterId[0].ContestMasterID}`
    );
    const activeData = await Axios.get(
      `https://api.tamasha.live/api/other/active_sub_contest/${MasterId[0].ContestMasterID}`
    );
    console.log({
      upcomingData: upcomingData.data,
      activeData: activeData.data,
    });
    return { upcomingData: upcomingData.data, activeData: activeData.data };
  } catch (err) {
    console.log(err);
  }
};
export const startGame = (id) => async (dispatch) => {
  try {
    const { data } = await Axios.get(
      `https://api.tamasha.live/api/other/questions_by_contest/${id}`
    );
    dispatch({ type: PUSH_QUESTION, payload: data });
    return data;
  } catch (err) {
    console.log(err);
  }
};
export const leaderBoard = (ContestID) => async (dispatch) => {
  try {
    const token=localStorage.getItem("token")

    console.log(ContestID);
    const resp = await Axios.post(
      `https://api.tamasha.live/api/quiz/show_leaderboard`,
      { ContestID: ContestID },
      {
        headers: {
          Accept: "application/json",
          Authorization:token

        },
      }
    );
    console.log("leader",resp.data)
    const { data } = await Axios.get(
      `https://api.tamasha.live/api/other/leaderboard_by_sub_contest/${ContestID}`
    );
    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
  }
};
export const publishQuestion = (data1) => async (dispatch) => {
  try {
    const token=localStorage.getItem("token")
    const { data } = await Axios.post(
      `https://api.tamasha.live/api/quiz/publish_question`,
      data1,
      {
        headers: {
          Accept: "application/json",
          Authorization:token
        },
      }
    );
    // dispatch({ type: PUSH_QUESTION, payload: data });
    return data;
  } catch (err) {
    console.log(err);
  }
};
export const endGameShow = (ContestID) => async (dispatch) => {
  try {
    const token=localStorage.getItem("token")

    const { data } = await Axios.post(
      `https://api.tamasha.live/api/quiz/stop_contest`,
      { ContestID: ContestID },
      {
        headers: {
          Accept: "application/json",
          Authorization:token

        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
  }
  
};
export const TotalNumberOfUser = (ContestID) => async (dispatch) => {
  try {
    const { data } = await Axios.get(
      `https://api.tamasha.live/api/other/total_users_in_a_event/${ContestID}`,
    );
    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
  }
};
