import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { startGame, TotalNumberOfUser } from "../redux/actions/contestActions";
import { connect } from "react-redux";

const ActiveContests = ({ data, history, startGame, TotalNumberOfUser }) => {
  const [date, setDate] = useState();
  const [registeredUser, setTotalRegisteredUser] = useState();
  const handleClick = async () => {
    console.log(data.ContestID);
    await startGame(data.ContestID);
    await localStorage.setItem("channel", data.ContestID);
    await history.push(`/game/${data.ContestID}`);
  };
  useEffect(() => {
    const totalUsers = async () => {
      const result = await TotalNumberOfUser(data.ContestID);
      console.log(result);
      setTotalRegisteredUser(result);
    };
    totalUsers();
    if (data) {
      var d1 = new Date(data.StartDate);
      const month = d1.toLocaleString("default", { month: "short" });
      const date1 = d1.getDate();
      const hour = d1.getHours();
      const min = d1.getMinutes();
      setDate(`${date1} ${month} || ${hour}:${min}`);
    }
  }, [data]);
  return (
    <div
      style={{
        width: "90%",
        padding: "20px",
        margin: "auto",
        color: "white",
        background: "#31347C",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4>{data?.ContestName || "Game Show"}</h4>
        <p>{date}</p>
      </div>
      {registeredUser?<p>Total User Registered: {registeredUser.totalUserRegistered}</p>:null}
      <hr />
      <Button onClick={handleClick} color="danger" style={{ width: "100%" }}>
        {" "}
        Start Now
      </Button>
    </div>
  );
};

export default connect(null, { startGame, TotalNumberOfUser })(
  withRouter(ActiveContests)
);
