import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import LeaderBoard from "../components/LeaderBoard";
import Broadcaster from "../broadcaster";
import {
  publishQuestion,
  endGameShow,
  leaderBoard,
} from "../redux/actions/contestActions";
import ChatFeature from "../components/ChatFeature";
import Questions from "../components/Questions";
import GiftFeature from "../components/GiftFeatures";
// import { stopBroadcast } from "../util";
const GamePage = (
  { questions, history, match, publishQuestion, endGameShow, leaderBoard },
  props
) => {
  const [question, setQuestion] = useState(null);
  const [channel, setChannel] = useState();
  const [timer, setTimer] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [endgame, setEndgame] = useState(false);
  const [gameStart, setGameStart] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [leaderboard, setLeaderboard] = useState(null);

  const [count, setCount] = useState(
    parseInt(localStorage.getItem("count")) || 0
  );
  useEffect(() => {
    const setup = async () => {
      await setChannel(match.params.ContestID);
    };
    setup();
  }, [count]);
  const handleLeaderboard = async () => {
    const resp = await leaderBoard(channel);
    await setCount(count+1);

    await setLeaderboard(resp);
  };
  const handleStartGame = async () => {
    await setGameStart(true);

    if (questions.length !== 0) {
      await localStorage.setItem("count", count + 1);
      await setQuestion(questions[count]);
      const res = await publishQuestion(questions[count]);

      await setTimer(questions[count].TimeLimit);
      timerFunction();
      setDisabled(true);
      setCount(count + 1);
      console.log(res, count);
    }

    if (questions.length === 0) {
      setGameStart(false);
      history.push("/");
    }
  };
  let interval;
  const timerFunction = () => {
    if (interval) clearInterval(interval);
    let t = questions[count].TimeLimit;
    interval = setInterval(() => {
      setTimer(t);
      if (t > 0) {
        t -= 1;
      } else {
        clearInterval(interval);
        setDisabled(false);
      }
    }, 1000);
  };
  const handlePushQuestion = async () => {
    if (questions.length === count + 1) {
      setEndgame(true);
    }
    if (questions.length === 0) {
      setGameStart(false);
      history.push("/");
    } else {
      if (count <= questions.length - 1) {
        await localStorage.setItem("count", count + 1);

        await setCount(count + 1);
        await setQuestion(questions[count]);
        const res = await publishQuestion(questions[count]);
        console.log(res, count);
        setTimer(questions[count].TimeLimit);
        timerFunction();
        setDisabled(true);
      } else {
        setGameOver(true);
        localStorage.removeItem("count");
      }
    }
  };
  return (
    <>
      <div>
        {questions.length !== 0 || gameOver? (
          <>                 
            <Broadcaster endGame={count  >= questions.length + 1} />
          </>
        ) : null}
        {gameOver ? (
          <div style={{ position: "absolute", bottom: "200px", width: "100%" }}>
            <h1 style={{ color: "wheat" }}>GameOver</h1>
            <Button
              style={{ marginTop: "5px" }}
              color="success"
              onClick={handleLeaderboard}
            >
              Show Leaderboard
            </Button>{" "}
            {/* <Link to="/">
              <Button
                onClick={async () => {
                  // await stopBroadcast()
                  await endGameShow(channel)}}
                style={{ marginTop: "5px" }}
                color="danger"
              >
                End Game
              </Button>
            </Link> */}
            {leaderboard ? <LeaderBoard data={leaderboard} /> : null}
          </div>
        ) : (
          <>
                 {questions.length !== 0 ? (
          <>
            <div
              style={{
                borderRadius: "15px",
                color: "white",
                position: "absolute",
                top: "50px",
                width: "100%",
                padding: "5px",
                opacity: 0.65,
              }}
            >
              <GiftFeature channel={match.params.ContestID} />
            </div>
          </>
        ) : null}
            {!gameStart ? (
              <Button className="btn-sm"
                color="danger"
                style={{ marginTop: "5px",  zIndex: "1"              }}
                onClick={handleStartGame}
              >
                {questions.length === 0 ? "Continue Show" : " Push Question"}
              </Button>
            ) : (
              <>
                {question ? (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "185px",
                      width: "100%",
                    }}
                  >

                    <div style={{ margin: "10px" }}>
                      <Button
                        color="danger"
                        disabled={disabled}
                        onClick={handlePushQuestion}
                      >
                        {endgame
                          ? "Go to Leaderboard page"
                          : "  Push next question"}
                      </Button>{" "}
                    </div>
                    <div
                      style={{
                        padding: "5px",
                        borderRadius: "5px",
                        margin: "5px",
                        backgroundColor: "white",

                        opacity: "0.5",
                      }}
                    >
                      <h3 style={{ margin: "0px" }}>{timer}</h3>
                      <Questions question={question} count={count} />
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </>
        )}
        {questions.length !== 0 ? (
          <>
            <div
              style={{
                borderRadius: "15px",
                color: "white",
                position: "absolute",
                bottom: "0px",
                width: "100%",
                padding: "5px",
                opacity: 0.65,
              }}
            >
              <ChatFeature channel={match.params.ContestID} />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  questions: state.contestState.questions,
});

export default connect(mapStateToProps, {
  publishQuestion,
  endGameShow,
  leaderBoard,
})(withRouter(GamePage));
