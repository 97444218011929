import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { endGameShow } from "./redux/actions/contestActions";

class Broadcaster extends Component {
  state = {
    token: "7072a31bc0a077ead981d1cf1ec18e279c01f43d96017cb1770631e624f51fc9",
    streamName: "kgsu27yx",
    accountID: "sNWxaW",
    spinner: false,
  };
  componentDidMount() {
    const script = document.createElement("script");
    script.src = require("./util");
    script.onload = () => {
      console.log("Loaded Script");
    };
    script.onerror = () => {
      console.log("error in loading script");
    };
    document.body.appendChild(script);
  }

  render() {
    console.log(localStorage.getItem("count") === 10);
    console.log(this.props.endGame, "Aaa");

    return (
      <div>
        <div id="vidView">
          <video
     style={{ width: "100vw", height: "100vh",WebkitTransform: "scaleX(-1)",
     transform: "scaleX(-1)" }}            autoPlay
            muted
            playsInline
          ></video>
        </div>

        <div className="controls">
          <div style={{ display: "none" }} id="form" className="">
            <input
              id="tokenTxt"
              className="base-input"
              type="text"
              defaultValue="7072a31bc0a077ead981d1cf1ec18e279c01f43d96017cb1770631e624f51fc9"
              placeholder="Token"
            />
            <input
              id="streamTxt"
              className="base-input ml-10 mr-10"
              type="text"
              defaultValue="kgsu27yx"
              placeholder="Stream Name"
            />
            <input
              id="viewTxt"
              className="base-input"
              type="text"
              defaultValue="sNWxaW"
              placeholder="Account Id"
            />
          </div>
          <div
            className="ml-10 mt-10"
            // style={{
            //   position: "absolute",
            //   top: "0px",
            //   right: "0px",
            //   margin: "10px",
            // }}
          >
            {this.props.endGame ? (
              <Link to="/">
                <Button
                  color="danger"
                  id="publishBtn1"
                  onClick={() => this.setState({ spinner: true })}
                  className="inline-b btn-sm"
                  onClick={async (e) => {
                    await this.props.endGameShow(
                      localStorage.getItem("channel")
                    );
                    await window.location.reload(false);
                  }}
                >
                  End Game Show
                </Button>
              </Link>
            ) : (
              <Button
                color="primary"
                id="publishBtn"
                onClick={() => this.setState({ spinner: true })}
                className="inline-b btn-sm"
                // onClick={(e)=> {}
              >
                {this.state.spinner ? "loading..." : " START LIVE"}
              </Button>
            )}
          </div>
          <div className="ml-10 mt-10">
            <a id="viewerUrl" href="" target="_blank"></a>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  endGameShow,
})(withRouter(Broadcaster));
