import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import UpcomingContest from "../components/UpcomingContest";
import { getAllContest } from "../redux/actions/contestActions";
import { Redirect } from "react-router-dom";
import ActiveContests from "../components/ActiveContests";
import NavBar from "../components/NavBar";

const HomePage = ({ getAllContest }) => {
  const [upcomingEvents, setUpcomingEvents] = useState(null);
  const [activeEvents, setActiveEvents] = useState(null);
  useEffect(() => {
    const fetchEvents = async () => {
      const resp = await getAllContest();
      if (resp) {
        if (resp.upcomingData !== null) {
          setUpcomingEvents(resp.upcomingData);
        }
        if (resp.activeData !== null) {
          setActiveEvents(resp.activeData);
        }
      }
    };
    fetchEvents();
  }, []);

  const user = localStorage.getItem("token");
  const render = user ? (
    
    <div>
      <NavBar/>
      <>
      <h1 style={{ margin: "30px", color: "white" }}>Upcoming Game Shows</h1>
      {upcomingEvents ? <UpcomingContest data={upcomingEvents} /> : <h2 style={{background:"white",margin:"10px",borderRadius:"15px",height:"40px"}}>No Upcoming gameshow</h2>}
      <br />
      <br />
      <br />
      <h1 style={{ margin: "30px", color: "white" }}>Active Game Shows</h1>
      {activeEvents ? <ActiveContests data={activeEvents} /> : <h2 style={{background:"white",margin:"10px",borderRadius:"15px",height:"40px"}}>No Active gameshow</h2>}
</>
    </div>
  ) : (
    <Redirect to="/user/login" />
  );
  return render;
};

export default connect(null, { getAllContest })(HomePage);
