import React, { Component } from "react";
const PubNub = require("pubnub");
const clientUUID = PubNub.generateUUID();
const pubnub = new PubNub({
  // replace the following with your own publish and subscribe keys
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  uuid: clientUUID,
});

class GiftFeature extends Component {
  state = {
    messages: [],
    input: "",
  };
  // messagesEndRef = React.createRef();
  // scrollToBottom = () => {
  //   this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  // };
  
  componentDidMount() {
    // this.scrollToBottom();
    pubnub.addListener({
      message: (event) => {
        console.log(event);
        console.log(event.message);
        this.setState({
          messages: [...this.state.messages, event.message].slice(-100),
        });
      },
    });

    pubnub.subscribe({
      channels: [localStorage.getItem("channel")],
      withPresence: true,
    });
  }
  componentDidUpdate() {
    // this.scrollToBottom();
  }
  submitUpdate = (anUpdate) => {
    if (anUpdate !== "") {
      const d1 = new Date();
      const hour = d1.getHours();
      const min = d1.getMinutes();
      const msg = {
        name: localStorage.getItem("contestName"),
        message: anUpdate,
        time: `${hour}:${min}`,
      };
      pubnub.publish(
        {
          channel: localStorage.getItem("channel"),
          message: msg,
        },
        (status, response) => {
          if (status.error) {
            console.log(status);
          } else {
            this.setState({ input: "" });
          }
        }
      );
    }
  };
  render() {
    return (
      <div>
        <header>
        <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100px",
          overflowY: "scroll",
        }}
      >
        {this.state.messages.map((message, messageIndex) => {
          return (
            <>
              {message.gift ? (
                <div key={`message-${messageIndex}+1`}>
                  <div
                    style={{
                      float: "left",
                      backgroundColor: "rgb(74,74,74",
                      margin: "3px",
                      color: "#fdfdfd",
                      display: "flex",
                      borderRadius: "13px",
                      padding: "0px 15px",
                      fontSize: "13px",
                      alignItems: "center",
                      width: "95%",
                      lineHeight: "0.5",
                      // justifyContent: "space-between",
                    }}
                  >
                    <b>{message.name} </b>
                    <p
                      style={{
                        margin: "10px",
                        color: "red",
                        fontWeight: "800",
                      }}
                    >
                      {"Sending " +
                        message.gift.name +
                        " worth " +
                        message.gift.giftamount +
                        "Rs"}
                    </p>
                  </div>
                </div>
              ) : null}
            </>
          );
        })}

        {/* <div ref={this.messagesEndRefGift} /> */}
      </div>           
        </header>
      </div>
      
    );
  }
}

export default GiftFeature;
