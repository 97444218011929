import React, { Component } from "react";
import GiftFeatures from "./GiftFeatures";
const PubNub = require("pubnub");
const clientUUID = PubNub.generateUUID();
const pubnub = new PubNub({
  // replace the following with your own publish and subscribe keys
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  uuid: clientUUID,
});

class ChatFeature extends Component {
  state = {
    messages: [],
    input: "",
  };
  messagesEndRef = React.createRef();
  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  
  componentDidMount() {
    console.log("Asasaasqwqwqw");
    this.scrollToBottom();
    pubnub.addListener({
      message: (event) => {
        console.log(event);
        console.log(event.message);
        this.setState({
          messages: [...this.state.messages, event.message].slice(-100),
        });
      },
    });

    pubnub.subscribe({
      channels: [localStorage.getItem("channel")],
      withPresence: true,
    });
  }
  componentDidUpdate() {
    this.scrollToBottom();
  }
  submitUpdate = (anUpdate) => {
    if (anUpdate !== "") {
      const d1 = new Date();
      const hour = d1.getHours();
      const min = d1.getMinutes();
      const msg = {
        name: localStorage.getItem("contestName"),
        message: anUpdate,
        time: `${hour}:${min}`,
      };
      pubnub.publish(
        {
          channel: localStorage.getItem("channel"),
          message: msg,
        },
        (status, response) => {
          if (status.error) {
            console.log(status);
          } else {
            this.setState({ input: "" });
          }
        }
      );
    }
  };
  render() {
    return (
      <div>
        <header>
          <div>
            {" "}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "150px",
                overflowY: "scroll",
              }}
            >
              {this.state.messages.map((message, messageIndex) => {
                return (
                  <div key={`message-${messageIndex}`}>
                  <div >
                    {message.gift ? (
                      <img
                        width="80px"
                        alt="gift"
                        src={`/${message.gift.picture}`}
                      />
                    ) : null}

                    <div
                      style={{
                        float: "left",
                        backgroundColor: "rgb(74,74,74",
                        margin: "3px",
                        color: "#fdfdfd",
                        display: "flex",
                        borderRadius: "13px",
                        // margin: "-10px 10px 5px",
                        padding: "0px 15px",
                        fontSize: "13px",
                        alignItems: "center",
                        width: "95%",
                        lineHeight: "0.5",
                        // justifyContent: "space-between",
                      }}
                    >
                      <b>{message.name}</b>
                      {message.gift ? (
                        <p
                          style={{
                            margin: "10px",
                            color: "red",
                            fontWeight: "800",
                          }}
                        >
                          {"Sending " +
                            message.gift.name +
                            " worth " +
                            message.gift.giftamount +
                            "Rs"}
                        </p>
                      ) : (
                        <p style={{ margin: "10px" }}>{message.message}</p>
                      )}
                      {/* <p
                        style={{
                          fontSize: "10px",
                          color: "#8a8b8c",
                          lineHeight: "2.5",
                        }}
                      >
                        {message.time}
                      </p> */}
                    </div>
                  </div>
                    </div>

                );
              })}
              <div ref={this.messagesEndRef} />
            </div>
            <div
              style={{
                display: "flex",
                height: "30px",
                justifyContent: "space-between",
                //   backgroundColor: "lightgrey",
              }}
            >
              <input
                type="text"
                style={{
                  borderRadius: "25px",
                  outline: "none",
                  borderColor: "transparent",
                  fontSize: "15px",
                  padding: "14px",
                  width: "95%",
                }}
                placeholder="Type your message"
                value={this.state.input}
                onChange={(e) => this.setState({ input: e.target.value })}
              />
              <button
                style={{
                  borderRadius: "5px",
                  fontSize: "16px",
                  backgroundImage: `url(https://emareye.com/mricfiles/uploads/2017/03/graysendbutton-copy-300x300.png)`,
                  backgroundSize: "28px",
                  backgroundRepeat: "no-repeat",
                  outline: "none",
                  width: "32px",
                  borderColor: "transparent",
                }}
                onClick={() => {
                  this.submitUpdate(this.state.input);
                }}
              ></button>
            </div>
          </div>
        </header>
      </div>
      
    );
  }
}

export default ChatFeature;
