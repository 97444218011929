const {

    PUSH_QUESTION, PUBLISH_QUESTION,
  } = require("../actionTypes");
  
  const initialState = {
    questions:[],
    error: null,
  };
  const contestReducer = (state = initialState, actions) => {
    const { type, payload } = actions;
    switch (type) {
      case PUSH_QUESTION:
        return { ...state, questions: payload };
        case PUBLISH_QUESTION:
          return { ...state, questions: payload };
      default:
        return state;
    }
  };
  export default contestReducer;
  