import React from "react";

const LeaderBoard = ({ data }) => {
  return (
    <>
      {" "}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "30px",
          fontSize: "17px",
          background: "white",
          borderRadius: "15px",
          margin: "10px",
          padding:"0px 20px",
          overflowY: "auto",
        }}
      >
        <p>Rank</p>
        {/* <p>Profile Picture</p> */}

        <p>Name</p>
        <p>Score</p>
      </div>
      <div style={{overflowY:"auto",height:"200px"}}>
      {data.map((player,index) => (
        <div key={player.PlayerID}
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "30px",
            fontSize: "17px",
            background: "white",
            borderRadius: "15px",
            margin: "10px",
            padding:"0px 20px",
          }}
        >
          <p>{index+1}</p>
          {/* <img
            src={player.PlayerImage}
            style={{ height: "30px", width: "30px", borderRadius: "50%" }}
            alt="profile pic"
          /> */}
          <p>{player.PlayerName}</p>
          <p>{player.PlayerScore}</p>
        </div>
      ))}
      </div>
    </>
  );
};

export default LeaderBoard;
