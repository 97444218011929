import React from "react";
import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";

import GamePage from "./pages/GamePage";
function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/user/Login" component={LoginPage} />
        <Route exact path="/" component={HomePage} />
        <Route exact path="/game/:ContestID" component={GamePage} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;
