import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "../styles/loginPage.css";
import { connect } from "react-redux";
import { loginUser } from "../redux/actions/userActions";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter a valid email address."),
  password: Yup.string()
    .min(8, "Password must be minimum length 0f 8!")
    // .matches(/[a-z]/, "Must have lowercase")
    // .matches(/[A-Z]/, "Must have uppercase")
    // .matches(/[0-9]/, "Must have number")
    .required("Required"),
});

const LoginPage = ({ history, loginUser }) => {
  const [error, setError] = useState("");
  const handleSubmit = async (data) => {
    const resp = await loginUser(data);
    if (resp) {
      history.push("/");
    } else {
      setError("Invalid Credentials");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };
  return (
    <div className="pageLayout">
      <div className="boxstyle">
        <h1 style={{ color: "white" }}>Sign In</h1>
        <br />
        <br />
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                color: "coral",
              }}
            >
              <Field
                style={{ fontSize: "18px", padding: "10px" }}
                className="input"
                name="email"
                type="email"
                placeholder="Email"
              />
              {errors.email && touched.email ? <p>{errors.email}</p> : <br />}
              <Field
                style={{ fontSize: "18px", padding: "10px" }}
                name="password"
                type="password"
                placeholder="Password"
              />
              {errors.password && touched.password ? (
                <p>{errors.password}</p>
              ) : (
                <br />
              )}
              {error ? <p>{error}</p> : null}
              <Button
                size="lg"
                color="danger"
                style={{ background: "red" }}
                type="submit"
              >
                Sign In
              </Button>
            </Form>
          )}
        </Formik>

        <p style={{ color: "#cacaca" }}>
          New User?{" "}
          <Link
            to="/user/register"
            style={{ fontSize: "16px", color: "white" }}
          >
            Sign Up Now
          </Link>
        </p>
      </div>
    </div>
  );
};
export default connect(null, { loginUser })(LoginPage);
