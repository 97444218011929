import React, { useState } from "react";
import { connect } from "react-redux";
import "../styles/navbar.css";
import { Link, withRouter } from "react-router-dom";
import { Button } from "reactstrap";
const NavBar = ({ history }) => {
  const handleLogout = async () => {
    console.log("assa");
    await localStorage.removeItem("token");
    await localStorage.removeItem("count");
    await localStorage.removeItem("sub-c-feb3fe62-0eb9-11eb-bc34-ce6fd967af95uuid");
    await localStorage.removeItem("channel");
    await localStorage.removeItem("contestName");
    history.push("/user/Login");
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "white",
        justifyContent: "space-between",
      }}
    >
      <Link to="/">
        <Button
          className="nav_logo "
          color="link"
          style={{ fontSize: "25px", boxShadow: "0 0 black" }}
        >
          Tamasha
        </Button>
      </Link>
      <Button
        onClick=""
        color="link"
        style={{ fontSize: "25px", boxShadow: "0 0 black" }}
        className="ButtonStyle"
        onClick={handleLogout}
      >
        Sign Out
      </Button>
    </div>
  );
};

export default connect(null, {})(withRouter(NavBar));
