const {
  REGISTER_USER,
  LOGIN_USER,
  LOGOUT_USER,
  HANDLE_ERROR,
} = require("../actionTypes");

const initialState = {
  user: null,
  error: null,
};
const userReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case REGISTER_USER:
      return { ...state, user: payload };
    case LOGIN_USER:
      console.log(payload)
      localStorage.setItem("token", payload.token);
      localStorage.setItem("contestName", payload.user_details.name)
      return { ...state, user: payload };
    case LOGOUT_USER:
      localStorage.removeItem("user");
      return { ...state, user: null };
    case HANDLE_ERROR:
      return { ...state, error: payload };
    default:
      return state;
  }
};
export default userReducer;
