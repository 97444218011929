import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { startGame } from "../redux/actions/contestActions";

const UpcomingContest = ({ data, startGame, history }) => {
  const [date, setDate] = useState();
  const handleClick = async () => {
    await startGame(data.ContestID);
    await history.push("/game");
  };
  useEffect(() => {
    if (data) {
      var d1 = new Date(data.StartDate);
      const month = d1.toLocaleString("default", { month: "short" });
      const date1 = d1.getDate();
      const hour = d1.getHours();
      const min = d1.getMinutes();
      setDate(`${date1} ${month} || ${hour}:${min}`);
    }
  }, [data]);
  return (
    <div
      style={{
        width: "350px",
        padding: "20px",
        margin: "auto",
        color: "white",
        background: "#31347C",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4>{data?.ContestName || "Game Show"}</h4>
        <p>{date}</p>
      </div>
      <hr />
      {/* <Button onClick={handleClick} color="danger" style={{ width: "100%" }}>
        Start Now
      </Button> */}
    </div>
  );
};
export default connect(null, { startGame })(withRouter(UpcomingContest));
