export const REGISTER_USER="REGISTER_USER"
export const LOGIN_USER="LOGIN_USER"
export const HANDLE_ERROR="HANDLE_ERROR"
export const LOGOUT_USER="LOGOUT_USER"
export const GET_ALL_MASTER_CONTEST="GET_ALL_MASTER_CONTEST"
export const GET_MASTER_CONTEST_BY_ID="GET_MASTER_CONTEST_BY_ID"
export const GET_SUB_CONTEST_BY_CONTEST_ID="GET_SUB_CONTEST_BY_CONTEST_ID"
export const GET_SUB_CONTEST="GET_SUB_CONTEST"
export const PUSH_QUESTION="PUSH_QUESTION"
export const PUBLISH_QUESTION="PUBLISH_QUESTION"

