import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { publishQuestion } from "../redux/actions/contestActions";
const Questions = ({ question, count }) => {
  return (
    <>
      {question ? (
        <>
          <div
            style={{
              border: "1px solid black",
              fontSize: "15px",
              fontWeight: "600",
              marginBottom: "5px",
            }}
          >
            {count}) {question.Question}
          </div>

          <div style={{ flexDirection: "column", display: "flex" }}>
            <Button
              color="success"
              style={{
                margin: "0px auto",
                color: "black",
                width: "80%",
              }}
            >
              Answer: {question.Answer}
            </Button>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Button
                style={{
                  margin: "5px",
                  backgroundColor: "white",
                  color: "black",
                  width: "45%",
                  textAlign: "justify",
                }}
              >
                A) {question.OptionA}
              </Button>
              <Button
                style={{
                  margin: "5px",
                  backgroundColor: "white",
                  color: "black",
                  width: "45%",
                  textAlign: "justify",
                }}
              >
                B) {question.OptionB}
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Button
                style={{
                  margin: "5px",
                  backgroundColor: "white",
                  color: "black",
                  width: "45%",
                  textAlign: "justify",
                }}
              >
                C) {question.OptionC}
              </Button>
              <Button
                style={{
                  margin: "5px",
                  backgroundColor: "white",
                  color: "black",
                  width: "45%",
                  textAlign: "justify",
                }}
              >
                D) {question.OptionD}
              </Button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  questions: state.contestState.questions,
});

export default connect(mapStateToProps, { publishQuestion })(
  withRouter(Questions)
);
