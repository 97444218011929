import contestReducer from "./reducers/contestReducer";
import userReducer from "./reducers/userReducer";

const { combineReducers } = require("redux");

const rootReducer=combineReducers({
userState:userReducer,
contestState:contestReducer

})
export default rootReducer