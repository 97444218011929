import Axios from "axios";
import { REGISTER_USER, LOGIN_USER, HANDLE_ERROR } from "../actionTypes";

export const registerUser = (playerID) => async (dispatch) => {
  try {
    const { data } = await Axios.post(
      `https://api.tamasha.live/api/player/followers/${playerID}?UserID=${playerID}`
    );
    // if (data.error) throw Error("error happened")
    return data;
  } catch (err) {
    console.log(err);
  }
};
export const loginUser = (data1) => async (dispatch) => {
  try {
    const { data } = await Axios.post(
      `https://api.tamasha.live/api/auth/admin/login`,
      data1,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    console.log(data);
   await localStorage.setItem("contestName", data.user_details.name);

    dispatch({ type: LOGIN_USER, payload: data });
    return data;
  } catch (err) {
    console.log(err.message);
    dispatch({ type: HANDLE_ERROR, payload: err.message });

    // console.log(err)
  }
};
